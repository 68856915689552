import React, { useCallback, useRef } from 'react';

import { useTranslate } from '../../features/polyglot';
import useAuth from 'hooks/useAuth';
import { toast } from 'react-toastify';

import SETTINGS_FORM_SCHEMA from 'ui-55/src/components/utils/settingsSchema';
import { Button, Form, Loader, BackofficeContainer } from 'ui-55';

import { LogoutContainer, FormContainer } from './style';

const Settings = () => {
  const t = useTranslate('settings');
  const translateForms = useTranslate('forms');

  const formRef = useRef();

  const { dispatchLogoutUser, user, updateUser, loading } = useAuth();
  const answers = { ...(user?.attributes ?? {}) };

  // ! Missing user answer for newsletter
  const handleLogout = useCallback(() => {
    dispatchLogoutUser();
  }, [dispatchLogoutUser]);

  const scrollToRef = (ref) => {
    ref.current.scrollIntoView();
  };

  const handleError = (errors) => {
    if (Object.keys(errors).length) {
      const errorField = Object.keys(errors)[0];
      const errorMsg =
        errors[errorField] === 'Obrigatório'
          ? 'Campo(s) obrigatório(s) por preencher'
          : errors[errorField];
      toast.error(errorMsg);
      scrollToRef(formRef);
    }
  };
  const handleSubmit = (vals) => {
    if (vals['email-check'] && vals['email-check'] !== user.attributes.email) {
      return toast.error(
        'O seu endereço de email não corresponde aos registos'
      );
    }
    if (
      vals['new-password'] &&
      vals['new-password'] !== vals['new-password-repeat']
    ) {
      return toast.error('As novas passwords não são iguais');
    }
    if (vals['new-email'] && vals['new-email'] !== vals['new-email-repeat']) {
      return toast.error('Os novos emails não são iguais');
    } else {
      const payload = {
        first_name: vals.firstName,
        last_name: vals.lastName,
        password: vals.password,
        email: vals['new-email'],
        new_password: vals['new-password'],
      };
      if (!payload.email) {
        delete payload.email;
      }
      if (!payload.new_password || !payload.password) {
        delete payload.password;
        delete payload.new_password;
      }
      updateUser(payload, () => {
        toast.success('Sucesso');

        // updateUser({
        //   first_name: vals.firstName,
        //   last_name: vals.lastName,
        //   email: vals.email,
        //   password: vals.password,
        //   new_email: vals['new-email'],
        //   new_password: vals['new-password']
      });
    }
  };

  return (
    <>
      <BackofficeContainer>
        <FormContainer>
          {!loading ? (
            <Form
              translate={translateForms}
              ref={formRef}
              onSubmit={handleSubmit}
              onError={handleError}
              questions={SETTINGS_FORM_SCHEMA.questions}
              answers={answers}
            />
          ) : (
            <Loader />
          )}
        </FormContainer>
        <LogoutContainer>
          <Button
            text={t('logout')}
            btnType='primary'
            action={handleLogout}
            isFullWidth
          />
        </LogoutContainer>
      </BackofficeContainer>
    </>
  );
};

export default Settings;
