import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useTranslate } from '../../features/polyglot';

import queryString from 'query-string';
import { toast } from 'react-toastify';
import { BackofficeContainer, GridCol, Tabs, Row, Loader, Form } from 'ui-55';
import SPECIALIST_FORM_SCHEMA from 'ui-55/src/components/utils/becomespecialist';

import { getExtraProps } from 'Redux/providers/selectors';
import useProvider from 'Hooks/providers/useProvider';
import useCandidateLead from 'Hooks/candidateLeads/useCandidateLead';

import SpecialistDetailRequestList from 'Components/SpecialistDetailRequestList';
import ProviderDetailsCard from 'Components/ProviderDetailsCard';
import ProviderStatusCard from 'Components/ProviderStatusCard';
import SpecialistConversationFeed from 'Components/SpecialistConversationFeed';
import PaymentRender from './paymentRender.js';
import { FormContainer, TabContainer } from './styles.js';
import CardTagProvider from 'Components/CardTagProvider';

const SpecialistDetails = () => {
  const match = useLocation();
  const t = useTranslate('specialists');
  const translateForms = useTranslate('forms');

  const providerId = queryString.parse(match.search).provider_id;

  const {
    provider,
    providerLoading: loading,
    updateProviderForm,
    providerForms
  } = useProvider({
    providerId: providerId,
    shouldFetch: true
  });
  const { updateCandidateLeadLoading } = useCandidateLead({
    candidateLeadId: provider?.id
  });

  const conversationId = provider?.attributes?.adminProviderConversationId;

  const extraProps = useSelector(state => getExtraProps(state));

  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [providerProps, setProviderProps] = useState(extraProps[provider?.id]);

  useEffect(() => {
    if (provider?.id) {
      setProviderProps(extraProps[provider?.id]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [provider?.id]);

  const initialValues = provider?.attributes?.formAnswers;

  const handleSubmit = values => {
    const submitObj = {
      form_answers: { ...initialValues, ...values }
    };
    updateProviderForm(submitObj, providerId, {
      success: () => {
        setSuccess(true);
        setError(false);
        toast.success(t('success'));
      },
      error: e => {
        toast.error(e);
        setError(true);
      }
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const removeSuccess = () => {
    setTimeout(() => {
      setSuccess(false);
    }, 5000);
  };

  useEffect(() => {
    if (success) {
      removeSuccess();
    }
    return () => {
      clearTimeout(removeSuccess);
    };
  }, [removeSuccess, success]);

  const scrollToRef = ref => {
    ref.current.scrollIntoView();
  };

  const formRef = useRef();
  const handleError = errors => {
    if (Object.keys(errors).length) {
      const errorField = Object.keys(errors)[0];
      const errorMsg =
        errors[errorField] === 'Obrigatório'
          ? 'Campo(s) obrigatório(s) por preencher'
          : errors[errorField];
      toast.error(errorMsg);
      scrollToRef(formRef);
    }
  };

  const tabsContent = useMemo(
    () => [
      {
        name: t('requestList'),
        children: () => <SpecialistDetailRequestList provider={provider} />
      },
      {
        name: t('payments'),
        children: () => <PaymentRender provider={provider} />
      },
      {
        name: t('details'),
        children: () => (
          <FormContainer ref={formRef} success={success} error={error}>
            <Form
              translate={translateForms}
              onError={handleError}
              submitLabel={success ? t('success') : t('submit')}
              onSubmit={values => handleSubmit(values)}
              questions={SPECIALIST_FORM_SCHEMA.questions}
              answers={providerForms.answers}
            />
          </FormContainer>
        )
      },
      {
        name: t('messages'),
        children: () => (
          <SpecialistConversationFeed
            conversationId={conversationId}
            providerId={provider?.id}
          />
        )
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [provider, t, success]
  );
  return (
    <>
      <BackofficeContainer>
        {(provider && !loading && !updateCandidateLeadLoading && (
          <>
            <Row style={{ display: 'flex', flexFlow: 'row nowrap' }}>
              <GridCol size={8}>
                <ProviderDetailsCard
                  provider={provider}
                  providerProps={providerProps}
                />
                <TabContainer>
                  <Tabs tabs={tabsContent} initialTabIndex={0} />
                </TabContainer>
              </GridCol>
              <GridCol size={1} />
              <GridCol size={4}>
                <ProviderStatusCard
                  provider={provider}
                  providerProps={providerProps}
                />
                <CardTagProvider provider={provider} />
              </GridCol>
            </Row>
          </>
        )) || <Loader />}
      </BackofficeContainer>
    </>
  );
};

export default SpecialistDetails;
