import React from 'react';
import { useTranslate } from '../../../features/polyglot';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { FileUploader } from 'ui-55';
import { Body } from 'Components/Text';

const FileUploaderContainer = styled.div`
  background: ${props => props.theme.colors.white};
  border: 1px dashed ${props => props.theme.colors.mediumBeige};
  box-sizing: border-box;
  border-radius: 4px;
  text-align: center;
  padding: 20px;
  outline: none;
  align-items: center;
`;

const DocumentsRender = ({ files, action }) => {
  const translate = useTranslate('specialists');
  return files && files.length > 0 ? (
    <FileUploader answers={files} action={action} translate={translate}/>
  ) : (
    <FileUploaderContainer  translate={translate}>
      <Body>{translate('noDocuments')}</Body>
    </FileUploaderContainer>
  );
};

DocumentsRender.propTypes = {
  files: PropTypes.array,
  action: PropTypes.func
};

export default DocumentsRender;
