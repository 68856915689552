import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import { useHistory } from 'react-router-dom';
import { useTranslate } from '../../features/polyglot';
import { toast } from 'react-toastify';
import {
  Row,
  Heading,
  Col,
  Form,
  Loader,
  BackofficeContainer,
  Icon,
  Avatar,
  Pagination,
} from 'ui-55';

import useClients from 'hooks/clients/useClients';
import useAppointment from 'Hooks/appointments/useAppointment';

import { Body } from 'Components/Text';
import Table from 'Components/SpecialistTable';
import Select from 'Components/Select';
import { getServiceForms } from 'Redux/services/actions';

import useServicesListing from 'Hooks/services/useServicesListing';
import useCities from 'Hooks/useCities';

import {
  ClientContainer,
  NewRequestFormContainer,
  AddClientModalContainer,
  IconContainer,
  IconDetails,
  ClientDetailsContainer,
  NoClientContainer,
  ClientHeaderDetails,
  ModalCustomStyles,
  SearchField
} from './styles';

const CreateNewRequest = ({ action }) => {
  const dispatch = useDispatch();
  const t = useTranslate('requests');
  const translateForms = useTranslate('forms');
  const history = useHistory();

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [submitedAnswers, setSubmitedAnswers] = useState({});
  const [selectedClient, setSelectedClient] = useState(null);
  const [selectedServiceForm, setSelectedServiceForm] = useState([]);
  const { dispatchGetCitiesListing, cities, getCityFromDistrict } = useCities();

  const {
    createNewAppointment,
    createNewAppointmentLoading,
  } = useAppointment();

  const { serviceForms } = useServicesListing({ shouldFetch: true });

  // bypass Filter component for simple Client queries
  const [clientPage, setClientPage] = useState(1);
  const [query, setQuery] = useState(`?page=${clientPage}`)
  const { clients, loading, totalPages } = useClients({query: query, shouldFetch: true})
  // set query if page number changes
  useEffect(() => {
    const [qString, pageNum] = query.split('page=');
    if (!(pageNum === clientPage)) setQuery(`${qString}page=${clientPage}`);
  }, [clientPage])
  // set query if search changes
  const handleQuery = _.debounce(function(e) {
    setClientPage(1)
    const newQ = e.length ? `?search=${e}&page=1` : `?page=1`;
    setQuery(newQ);
  }, 1500)

  const handlePaginationNavigation = useCallback((pageNumber) => {
    setClientPage(parseInt(pageNumber));
  }, []);

  const scrollToRef = (ref) => {
    ref.current.scrollIntoView();
  };

  const formRef = useRef();
  const handleError = (errors) => {
    if (Object.keys(errors).length) {
      const errorField = Object.keys(errors)[0];
      const errorMsg =
        errors[errorField] === 'Obrigatório'
          ? 'Campo(s) obrigatório(s) por preencher'
          : errors[errorField];
      toast.error(errorMsg);
      scrollToRef(formRef);
    }
  };

  useEffect(() => {
    getServiceForms(dispatch);
    if (!cities?.length) {
      dispatchGetCitiesListing();
    }
  }, [cities.length, dispatch, dispatchGetCitiesListing]);

  const handleClientSelection = useCallback(
    (client) => {
      setSelectedClient(client);
      if (client) {
        setModalIsOpen(!modalIsOpen);
      }
    },
    [modalIsOpen, selectedClient]
  );

  const formSelectionOptions = useMemo(() => {
    if (!serviceForms) return;
    const { data } = serviceForms;

    const formSelectOptions =
      data &&
      data
        .filter((e) => e?.attributes?.service_name !== 'Reparações')
        .map((e) => {
          return {
            label: e?.attributes?.service_name,
            value: e?.attributes?.service_id,
          };
        });
    return formSelectOptions;
  }, [serviceForms]);

  const handleServiceFormSelection = useCallback(
    (event) => {
      if (!serviceForms) return;
      const form = serviceForms.data.find(
        (form) => form?.attributes?.service_id === event.value
      );
      setSelectedServiceForm(form);
    },
    [serviceForms]
  );

  const handleSubmit = (answers) => {
    const cityID = getCityFromDistrict(answers);

    const submitObj = {
      lead: {
        client_id: selectedClient?.id,
        city_id: cityID,
        service_id: selectedServiceForm?.attributes?.service_id,
        service_type: answers['service-type'],
        form_answers: answers,
      },
    };

    setSubmitedAnswers(answers);
    createNewAppointment(submitObj, {
      success: () => {
        toast.success(t('successSubmit'));
        history.push('/dashboard/requests?kanban=true');
      },
      error: (e) => {
        toast.error(e);
      },
    });
  };

  const renderClient = () => {
    return (
      <>
        <Row justify='space-between'>
          <ClientHeaderDetails>
            <Heading size={5}>{t('client')}</Heading>
            <span role='presentation' onClick={() => setSelectedClient(null)}>
              <Icon name='trash' />
            </span>
            <Row>
              <Avatar
                size='medium'
                user={selectedClient?.attributes}
                hasText={true}
              />
            </Row>
          </ClientHeaderDetails>
          <ClientDetailsContainer justify='space-between'>
            <Col>
              <IconDetails>
                <IconContainer>
                  <Icon name='phone' />
                </IconContainer>
                <Body>{selectedClient?.attributes?.phoneNumber}</Body>
              </IconDetails>
            </Col>
            <Col>
              <IconDetails>
                <IconContainer>
                  <Icon name='mail' />
                </IconContainer>
                <Body>{selectedClient?.attributes?.email}</Body>
              </IconDetails>
            </Col>
          </ClientDetailsContainer>
        </Row>
      </>
    );
  };

  return (
    <>
      <BackofficeContainer>
        {createNewAppointmentLoading ? (
          <Loader />
        ) : (
          <Row>
            <Col size={8}>
              <ClientContainer>
                {!selectedClient && (
                  <NoClientContainer>
                    <Heading size={5}>{t('client')}</Heading>
                    <div
                      role='presentation'
                      onClick={() => setModalIsOpen(!modalIsOpen)}
                    >
                      <Icon name='Plus' />
                      {t('addClient')}
                    </div>
                  </NoClientContainer>
                )}
                {selectedClient && renderClient()}
              </ClientContainer>
              <NewRequestFormContainer justify='start' ref={formRef}>
                <Form
                  translate={translateForms}
                  submitLabel={t('saveAction')}
                  questions={selectedServiceForm?.attributes?.questions?.questions}
                  onError={handleError}
                  answers={submitedAnswers}
                  onSubmit={(answers) => handleSubmit(answers)}
                  hiddenFields={['login-buttons', 'Outro']}
                >
                  <Heading size={5}>{t('aboutService')}</Heading>
                  <Select
                    options={formSelectionOptions}
                    onChange={(event) => handleServiceFormSelection(event)}
                  />
                </Form>
              </NewRequestFormContainer>

              <ReactModal
                style={ModalCustomStyles}
                ariaHideApp={false}
                isOpen={modalIsOpen}
              >
                <AddClientModalContainer>
                  <span
                    className='client-modal-close-button'
                    role='presentation'
                    onClick={() => {
                      setModalIsOpen(!modalIsOpen);
                    }}
                  >
                    <Icon name='Close' />
                  </span>
                  <Heading size={3}>{t('addClient')}</Heading>
                  <SearchField>
                    <Icon name='Search' />
                    <input
                      defaultValue={''}
                      onChange={(v) => handleQuery(v?.currentTarget?.value)}
                    />
                  </SearchField>
                  {loading ? (
                    <Loader />
                  ) : (
                    <>
                      <Table
                        hasFilter={true}
                        isFilterable={false}
                        columns={[]}
                        action={(client) => handleClientSelection(client)}
                        items={clients}
                        isFullWidth
                      />
                      <Pagination
                        translate={t}
                        totalPages={totalPages}
                        currentPage={clientPage}
                        action={handlePaginationNavigation}
                      />
                    </>
                  )}
                </AddClientModalContainer>
              </ReactModal>
            </Col>
            <Col size={4} />
          </Row>
        )}
      </BackofficeContainer>
    </>
  );
};

CreateNewRequest.propTypes = {
  action: PropTypes.func,
};

export default CreateNewRequest;
