import React, { useMemo, useRef, useState, useCallback } from 'react';
import { toast } from 'react-toastify';
import { useLocation, useHistory } from 'react-router-dom';
import { useTranslate } from '../polyglot';
import queryString from 'query-string';
import useClient from 'Hooks/clients/useClient';

import {
  BackofficeContainer,
  Row,
  Form,
  Tabs,
  Col,
  Body,
  Heading,
  Loader,
} from 'ui-55';
import Modal from 'Components/Modal';
import ProviderDetailsCard from 'Components/ProviderDetailsCard';
import ClientDetailServicesCard from 'Components/ClientDetailServicesCard';
import ClientDetailRequestList from 'Components/ClientDetailRequestList';
import styled from 'styled-components';


export const FormContainer = styled(Row)`
  align-content: flex-start;
  width: auto;
  margin: 0;
  > div {
    max-width: 100%;
  }
`;

export const TabContainer = styled.div`
  flex: 1;
  margin-top: ${props => props.theme.margin * 2}px;
  > div > div > button {
    margin: 0 16px 16px 0;
  }
`;


export const ClientDetail = () => {
  const match = useLocation();
  const history = useHistory();
  const translate = useTranslate('clientDetail');
  const translateForm = useTranslate('forms');
  const formRef = useRef();
  const clientId = queryString.parse(match.search)?.client_id;
  const {
    client,
    updateClient,
    clientError,
    clientLoading,
    deleteClient,
  } = useClient({
    clientId: clientId,
    shouldFetch: true,
  });

  const FIELDS = {
    type: 'form',
    questions: [
      {
        type: 'object',
        key: 'pers-details-form',
        label: translate('personalData'),
        isOpen: true,
        questions: [
          {
            type: 'radio',
            key: 'clientType',
            label: '',
            options: [
              {
                value: 0,
                label: 'Particular',
              },
              {
                value: 1,
                label: 'Empresa',
              },
            ],
          },
          { type: 'text', key: 'name', label: translate('name') },
          { type: 'text', key: 'surname', label: translate('surname') },
          { type: 'email', key: 'email', label: translate('email') },
          { type: 'text', key: 'phone', label: translate('phone') },
          { type: 'text', key: 'nif', label: translate('nif') },
          {
            type: 'dropdown',
            key: 'district',
            label: translate('district'),
            widget: 'district',
          },
          { type: 'text', key: 'address', label: translate('address') },
          { type: 'text', key: 'postalCode', label: translate('postalCode') },
          { type: 'text', key: 'local', label: translate('local') },
        ],
      },
    ],
  };

  const dividedName = useMemo(() => client?.attributes?.fullName?.split(' '), [
    client,
  ]);

  const answers = useMemo(() => {
    if (!client) {
      return null;
    }
    const clientTypes = { individual: 0, business: 1 };
    const data = {
      clientType: clientTypes[client?.attributes?.clientType],
      name: dividedName?.[0],
      surname: dividedName?.slice(1)?.join(' ') || '',
      email: `${client ? client?.attributes?.email : ''}`,
      phone: client?.attributes?.phoneNumber,
      nif: client?.attributes?.nif,
      address: client?.attributes?.address,
      postalCode: client?.attributes?.postalCode,
      local: client?.attributes?.city,
      district: client?.attributes?.district,
      district_parish: client?.attributes?.districtParish,
    };
    return data;
  }, [client, dividedName]);
  const scrollToRef = (ref) => {
    ref.current.scrollIntoView();
  };

  const handleError = (errors) => {
    if (Object.keys(errors).length) {
      const errorField = Object.keys(errors)[0];
      const errorMsg =
        errors[errorField] === 'Obrigatório'
          ? 'Campo(s) obrigatório(s) por preencher'
          : errors[errorField];
      toast.error(errorMsg);
      scrollToRef(formRef);
    }
  };

  const [openModal, setOpenModal] = useState(false);
  const tabsContent = [
    {
      name: translate('requestList'),
      children: () => <ClientDetailRequestList clientId={clientId} />,
    },
    {
      name: translate('details'),
      children: () => (
        <>
          {clientLoading ? (
            <Loader />
          ) : (
            <FormContainer ref={formRef}>
              <Modal
                isOpen={openModal}
                onClose={() => setOpenModal(!openModal)}
                onSuccess={handleDetele}
              >
                <Row>
                  <Col>
                    <Heading size={3}>{translate('deleteModalHeader')}</Heading>
                    <Body>{translate('deleteModalBody')}</Body>
                  </Col>
                </Row>
              </Modal>
              {answers && (
                <Form
                  translate={translateForm}
                  onError={handleError}
                  submitLabel={translate('updateClient')}
                  questions={FIELDS?.questions}
                  answers={answers}
                  onSubmit={(answers) => handleSubmit(answers)}
                ></Form>
              )}
              <Row justify='space-between'>
                <Col size={4}>
                  <ClientDetailServicesCard
                    isEraseButton
                    action={() => setOpenModal(!openModal)}
                  />
                </Col>
                <Col size={4}></Col>
              </Row>
            </FormContainer>
          )}
        </>
      ),
    },
  ];

  function handleSubmit(data) {
    const fullName = `${data?.name} ${data?.surname}`;

    const submitObj = {
      email: data?.email,
      full_name: fullName,
      address: data?.address,
      postal_code: data?.postalCode,
      city: data?.local,
      nif: data?.nif,
      phone_number: data?.phone,
      client_type: data?.clientType,
      district: data?.district,
      district_parish: data?.district_parish,
    };

    updateClient(submitObj, clientId);
  }

  const handleDetele = useCallback(() => {
    deleteClient(clientId);
    if (!clientLoading && !clientError) history.push('/dashboard/clients');
  }, [clientError, clientId, clientLoading, deleteClient, history]);

  return (
    <>
      <BackofficeContainer>
        {clientLoading ? (
          <Loader />
        ) : (
          <>
            <Row justify='space-around' size={1}>
              <Col size={8}>
                <ProviderDetailsCard provider={client} />
              </Col>
              <Col size={4}>
                <ClientDetailServicesCard data={client} />
              </Col>
            </Row>
            <Row>
              <TabContainer>
                <Tabs tabs={tabsContent} initialTabIndex={0} />
              </TabContainer>
            </Row>
          </>
        )}
      </BackofficeContainer>
    </>
  );
};


